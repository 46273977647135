import * as React from 'react';

import { Box, Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, Typography } from '@mui/material';
import { Download, ArrowDropDown } from '@mui/icons-material';

export default function DownloadButton() {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	return (
		<React.Fragment>
			<ButtonGroup variant='contained' ref={anchorRef}>
				<Button onClick={() => window.open('/updates/Acumatica-Dev-Tools-Latest.exe', '_blank')} size='large' endIcon={<Download />}>
					Download for Windows
				</Button>
				<Button onClick={handleToggle}>
					<ArrowDropDown />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				placement='bottom-end'
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<Box sx={{ p: 1, width: 285 }}>
									<Button variant='outlined' color='warning' endIcon={<Download />} onClick={() => window.open('/updates/Acumatica-Dev-Tools-Beta.exe', '_blank')} sx={{ mb: 0.5 }}>
										Download Preview Version
									</Button>
									<Typography variant='body2' color='text.secondary'>
										The preview version can be unstable.
									</Typography>
								</Box>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
}

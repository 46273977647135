import React from 'react';
import './style.css';

import { AppBar, Button, Container, CssBaseline, Dialog, Grid, Link, Stack, ThemeProvider, Toolbar, Typography, Zoom, createTheme } from '@mui/material';

import { AcumaticaIcon } from './SvgIcons';
import { TransitionProps } from '@mui/material/transitions';
import DownloadButton from './DownloadButton';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Zoom ref={ref} {...props} />;
});

function App() {
	const theme = createTheme({
		palette: {
			primary: {
				light: '#80e27e',
				main: '#4caf50',
				dark: '#087f23',
				contrastText: '#fff',
			},
			secondary: {
				light: '#ff7961',
				main: '#f44336',
				dark: '#ba000d',
				contrastText: '#000',
			},
		},
	});

	const [imgDialogOpen, setImgDialogOpen] = React.useState(false);
	const [imgDialog, setImgDialog] = React.useState('');

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />

			<AppBar position='static' color='default' elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
				<Container maxWidth='lg'>
					<Toolbar>
						<AcumaticaIcon color='primary' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

						<Typography variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
							Acumatica Dev Tools
						</Typography>

						<Stack direction='row' spacing={2}>
							<Button variant='outlined' color='primary' component={Link} href='/updates/Acumatica-Dev-Tools-Latest.exe' target='_blank'>
								Download
							</Button>
						</Stack>
					</Toolbar>
				</Container>
			</AppBar>

			<Container maxWidth='xl' component='main' sx={{ paddingTop: { xs: 2.5, md: 10 } }}>
				<Grid container spacing={8} alignItems='flex-start'>
					<Grid item md={7} sm={12}>
						<Typography variant='h2' color='primary' sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom>
							Powerful, yet simple to use: Development Tools for Acumatica ERP
						</Typography>

						<Typography variant='h6' color='primary' sx={{ fontWeight: 'bold' }} gutterBottom>
							Get the latest Acumatica Dev Tools
						</Typography>
						{/* <Stack direction='row' spacing={2}>
							<Button variant='contained' size='large' color='primary' endIcon={<Download />} component={Link} href='/updates/Acumatica-Dev-Tools-Latest.exe' target='_blank'>
								Download
							</Button>
							<Button variant='outlined' size='medium' color='warning' endIcon={<Download />} component={Link} href='/updates/Acumatica-Dev-Tools-Beta.exe' target='_blank'>
								Beta Version
							</Button>
						</Stack> */}

						<DownloadButton />
						<Typography variant='body2' sx={{ mt: 1 }}>
							Windows 10/11 64-bit only.
						</Typography>
					</Grid>
					<Grid item md={5} sm={12}>
						<img
							src='/img/acumatica-dev-tools.png'
							alt='acumatica-dev-tools'
							style={{ maxWidth: '100%' }}
							className='landingImage'
							onClick={(e) => {
								// @ts-ignore
								setImgDialog(e.target.src);
								setImgDialogOpen(true);
							}}
						/>
					</Grid>

					<Grid item sm={12}>
						<Typography variant='h3' align='center'>
							Features
						</Typography>
					</Grid>
					<Grid item md={5} sm={12}>
						<img
							src='/img/instanceContextMenu.png'
							alt='Instance Management'
							style={{ maxWidth: '100%' }}
							className='landingImage'
							onClick={(e) => {
								// @ts-ignore
								setImgDialog(e.target.src);
								setImgDialogOpen(true);
							}}
						/>
					</Grid>
					<Grid item md={7} sm={12}>
						<Typography variant='h5' color='primary' sx={{ fontWeight: 'bold' }} gutterBottom>
							Instance Management
						</Typography>
						<Typography variant='body1'>Use the dev tools to create, modify and delete Acumatica instances. Also run quick actions to support the development process.</Typography>
					</Grid>
					<Grid item md={7} sm={12}>
						<Typography variant='h5' color='primary' sx={{ fontWeight: 'bold' }} gutterBottom>
							Build Management
						</Typography>
						<Typography variant='body1'>
							Download and extract Acumatica builds with one click, let the dev tools do the rest! Use the quick actions to launch the Configuration Wizard, Report Designer & Device Hub.
							Also quickly open Acumatica source code in your IDE of choice.
						</Typography>
					</Grid>
					<Grid item md={5} sm={12}>
						<img
							src='/img/buildManagement.png'
							alt='Build Management'
							style={{ maxWidth: '100%' }}
							className='landingImage'
							onClick={(e) => {
								// @ts-ignore
								setImgDialog(e.target.src);
								setImgDialogOpen(true);
							}}
						/>
					</Grid>
					<Grid item md={5} sm={12}>
						<img
							src='/img/tenantSetup.png'
							alt='Tenant Setup'
							style={{ maxWidth: '100%' }}
							className='landingImage'
							onClick={(e) => {
								// @ts-ignore
								setImgDialog(e.target.src);
								setImgDialogOpen(true);
							}}
						/>
					</Grid>
					<Grid item md={7} sm={12}>
						<Typography variant='h5' color='primary' sx={{ fontWeight: 'bold' }} gutterBottom>
							Tenant Setup
						</Typography>
						<Typography variant='body1'>Create, rename and delete tenants in existing instances. Even insert demo data.</Typography>
					</Grid>
					<Grid item md={7} sm={12}>
						<Typography variant='h5' color='primary' sx={{ fontWeight: 'bold' }} gutterBottom>
							Highly Customizable
						</Typography>
						<Typography variant='body1'>Customize app features, install plugins and debug.</Typography>
					</Grid>
					<Grid item md={5} sm={12}>
						<img
							src='/img/settings.png'
							alt='Settings'
							style={{ maxWidth: '100%' }}
							className='landingImage'
							onClick={(e) => {
								// @ts-ignore
								setImgDialog(e.target.src);
								setImgDialogOpen(true);
							}}
						/>
					</Grid>
				</Grid>
			</Container>

			<Dialog maxWidth='xl' TransitionComponent={Transition} onClose={() => setImgDialogOpen(false)} open={imgDialogOpen}>
				<img
					src={imgDialog}
					alt='dialog-img'
					style={{ maxWidth: '100%' }}
					onClick={() => {
						setImgDialogOpen(false);
					}}
				/>
			</Dialog>

			<AppBar color='default' position='static' sx={{ mt: 8 }}>
				<Toolbar variant='dense' sx={{ display: 'grid' }}>
					<Typography variant='body2' color='text.secondary' align='center'>
						{'Copyright © '}
						<Link color='primary' href='https://www.crestwood.com/' target='_blank'>
							Crestwood Associates LLC
						</Link>{' '}
						{new Date().getFullYear()} • Site design by{' '}
						<Link color='primary' href='https://github.com/EvanTrow' target='_blank'>
							Evan Trowbridge
						</Link>
					</Typography>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default App;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AcumaticaIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props} viewBox='0 0 1024 1024'>
			{/* <path d='M24.0648649,24.089616 L17.2948949,24.089616 C13.5553153,24.089616 10.5249249,21.068976 10.5249249,17.344656 C10.5249249,13.617936 13.5553153,10.595376 17.2948949,10.595376 C21.0344745,10.595376 24.0648649,13.617936 24.0648649,17.344656 L24.0648649,24.089616 Z M17.2948949,0.282576 C7.8375976,0.282576 0.172972973,7.921776 0.172972973,17.344656 C0.172972973,26.768496 7.8375976,34.405776 17.2948949,34.405776 L34.4168168,34.405776 L34.4168168,17.344656 C34.4168168,7.921776 26.7497898,0.282576 17.2948949,0.282576 L17.2948949,0.282576 Z' /> */}

			<defs>
				<linearGradient id='adt-linear-gradient' x1='558.94' y1='657.16' x2='233.49' y2='1608.73' gradientTransform='translate(0 -606.11)' gradientUnits='userSpaceOnUse'>
					<stop offset='0' stop-color='#009507' />
					<stop offset='.11' stop-color='#06ac0d' />
					<stop offset='.37' stop-color='#28c22f' />
					<stop offset='.62' stop-color='#2dcd34' />
					<stop offset='.83' stop-color='#29f731' />
					<stop offset='1' stop-color='#2cff35' />
				</linearGradient>
				<linearGradient id='adt-linear-gradient-2' x1='315.26' y1='1244.83' x2='1059.72' y2='1284.28' gradientTransform='translate(0 -606.11)' gradientUnits='userSpaceOnUse'>
					<stop offset='0' stop-color='#009507' />
					<stop offset='.11' stop-color='#06ac0d' />
					<stop offset='.38' stop-color='#28c22f' />
					<stop offset='.62' stop-color='#2dcd34' />
					<stop offset='.83' stop-color='#29f731' />
					<stop offset='1' stop-color='#2cff35' />
				</linearGradient>
				<linearGradient id='adt-linear-gradient-3' x1='1001.81' y1='1097.81' x2='247.42' y2='846.3' gradientTransform='translate(0 -606.11)' gradientUnits='userSpaceOnUse'>
					<stop offset='0' stop-color='#009507' />
					<stop offset='.11' stop-color='#06ac0d' />
					<stop offset='.37' stop-color='#28c22f' />
					<stop offset='.62' stop-color='#2dcd34' />
					<stop offset='.83' stop-color='#29f731' />
					<stop offset='1' stop-color='#2cff35' />
				</linearGradient>
				<linearGradient id='adt-linear-gradient-4' x1='657.57' y1='1418.63' x2='672.37' y2='950.25' gradientTransform='translate(0 -606.11)' gradientUnits='userSpaceOnUse'>
					<stop offset='0' stop-color='#2cff35' stop-opacity='0' />
					<stop offset='1' stop-color='#2cff35' />
				</linearGradient>
			</defs>

			<path
				d='M427.56,943.52c62.52,50.3,142.28,80.48,229.23,80.48h-144.44c-141.56,0-269.47-57.49-362.17-150.19C57.49,781.11,0,653.2,0,511.64,0,229.23,229.23,0,511.64,0c59.64,0,117.13,10.06,171.03,29.46-33.06-10.06-68.27-15.09-104.2-15.09-151.62,0-282.41,91.98-338.46,223.48-18.68,43.83-29.46,92.7-29.46,143.72,0,86.95,30.18,166.71,80.48,229.95-1.44,15.09-2.87,30.18-2.87,45.27,0,101.32,40.96,193.3,107.79,259.41,10.78,10.06,20.84,19.4,31.62,27.31h0Z'
				fill='url(#adt-linear-gradient)'
			/>
			<path
				d='M1024,656.8v367.2h-367.2c-86.95,0-166.71-30.18-229.23-80.48-10.78-8.62-20.84-17.25-30.18-27.31-66.83-66.11-107.79-158.09-107.79-259.41,0-15.09,.72-30.18,2.87-45.27,8.62,10.06,17.25,20.12,26.59,29.46,66.83,66.11,158.09,107.79,259.41,107.79h172.46v-172.46c0-114.98-52.46-217.02-135.1-284.56,13.65-1.44,26.59-2.16,40.24-2.16,117.13,0,222.05,55.33,288.88,140.84,49.58,62.52,79.05,140.84,79.05,226.36h0Z'
				fill='url(#adt-linear-gradient-2)'
			/>
			<path
				d='M1024,512.36v144.44c0-85.51-28.74-163.84-78.33-226.36-67.55-85.51-171.74-140.84-288.88-140.84-13.65,0-27.31,.72-40.24,2.16-168.87,18.68-303.25,151.62-324.09,319.78-50.3-63.24-80.48-143-80.48-229.95,0-51.02,10.06-99.17,29.46-143.72C297.5,106.35,427.56,14.37,579.91,14.37c35.93,0,71.14,5.03,104.2,15.09,43.12,15.09,83.36,35.93,120.01,61.08,132.22,92.7,219.89,247.2,219.89,421.82h0Z'
				fill='url(#adt-linear-gradient-3)'
			/>
			<path
				d='M1024,656.8v367.2h-367.2c-86.95,0-166.71-30.18-229.23-80.48-10.78-8.62-20.84-17.25-30.18-27.31-66.83-66.11-107.79-158.09-107.79-259.41,0-15.09,.72-30.18,2.87-45.27,8.62,10.06,17.25,20.12,26.59,29.46,66.83,66.11,158.09,107.79,259.41,107.79h172.46v-172.46c0-114.98-52.46-217.02-135.1-284.56,13.65-1.44,26.59-2.16,40.24-2.16,117.13,0,222.05,55.33,288.88,140.84,49.58,62.52,79.05,140.84,79.05,226.36h0Z'
				fill='url(#adt-linear-gradient-4)'
			/>
		</SvgIcon>
	);
}
